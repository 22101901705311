































































































































import Vue from "vue";
import AppPageBanner from "../../components/layout/AppPageBanner.vue";
import AppPage from "../../components/layout/AppPage.vue";
import { mapGetters } from "vuex";
import { loginRequest } from "@/configs/authConfig";
import {
  ApiException,
  UserAccountUpdateBasicInformationCommand,
  UserAccountUpdateEmailCommand,
  UserAccountUpdateWorkInformationCommand,
  UserClient,
  UserFullProfileResponse,
  UserProfileStatus,
} from "@/clients/clients";
import AppPageLoading from "@/components/layout/AppPageLoading.vue";

export default Vue.extend({
  name: "AccountView",
  components: { AppPageLoading, AppPage, AppPageBanner },
  data: () => ({
    loading: false,
    errorMessage: "",
    tab: null as number | null,
    profile: new UserFullProfileResponse({
      webinars: [],
      certificates: [],
      id: "",
      createdAt: null,
      updatedAt: null,
      title: null,
      firstName: null,
      lastName: null,
      profession: null,
      speciality: null,
      specialityOther: null,
      phoneNumber: null,
      email: null,
      professionalCode: null,
      organisationName: null,
      department: null,
      streetAddress: null,
      streetAddress2: null,
      city: null,
      postCode: null,
      country: null,
      interests: null,
      isHealthcareProfessional: false,
      consentToMarketing: false,
      consentToFollowUp: false,
      verified: false,
      blocked: false,
      profileStatus: UserProfileStatus.Incomplete,
    }),
  }),
  computed: {
    ...mapGetters(["account"]),
  },
  async created() {
    await this.getProfile();
  },
  methods: {
    async getProfile(): Promise<void> {
      try {
        this.loading = true;
        const token = await this.$auth.acquireTokenSilent(loginRequest);
        const client = this.$httpClientFactory(UserClient, token.accessToken);
        const profile = await client.get();
        this.profile = Object.assign({}, profile);
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
    async updateDetails(): Promise<void> {
      try {
        this.loading = true;
        const token = await this.$auth.acquireTokenSilent(loginRequest);
        const client = this.$httpClientFactory(UserClient, token.accessToken);
        await client.updateBasicInformation(
          new UserAccountUpdateBasicInformationCommand(this.profile)
        );
        await client.updateWorkInformation(
          new UserAccountUpdateWorkInformationCommand(this.profile)
        );
        const profile = await client.get();
        this.profile = Object.assign({}, profile);
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
    async updateEmail(): Promise<void> {
      try {
        this.loading = true;
        const token = await this.$auth.acquireTokenSilent(loginRequest);
        const client = this.$httpClientFactory(UserClient, token.accessToken);
        await client.updateEmail(
          new UserAccountUpdateEmailCommand({ email: this.profile.email })
        );
        const profile = await client.get();
        this.profile = Object.assign({}, profile);
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
  },
});
