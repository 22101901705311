















import Vue from "vue";
import AppPage from "@/components/layout/AppPage.vue";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";

export default Vue.extend({
  name: "AppPageError",
  components: { AppPageBanner, AppPage },
  data: () => ({}),
});
