




import Vue from "vue";
import AppPageError from "@/components/layout/AppPageError.vue";

export default Vue.extend({
  name: "ErrorViewUnauthorised",
  components: { AppPageError },
  data: () => ({}),
});
